/* This stylesheet generated by Transfonter (https://transfonter.org) on April 24, 2018 11:14 AM */

@font-face {
    font-family: 'Century Gothic';
    src: url('/fonts/CenturyGothic.woff2') format('woff2'),
        url('/fonts/CenturyGothic.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Century Gothic';
    src: url('/fonts/CenturyGothic-Bold.woff2') format('woff2'),
        url('/fonts/CenturyGothic-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}
